import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "modules/firebase";
import { deleteAnswer, listAnswers } from "./answers";
import { deleteQuestion, listQuestions } from "./questions";

export const createEvent = async ({ projectId, event }) => {
  const eventsRef = collection(firestore, "projects", projectId, "events");

  const eventRef = await addDoc(eventsRef, {
    name: event.name,
    date: event.date,
    description: event.description,
    headers: {
      0: { question: null, accumulator: null, text: null },
      1: { question: null, accumulator: null, text: null },
      2: { question: null, accumulator: null, text: null },
    },
    questions_ids: [],
    guests: event.guests,
    created_at: serverTimestamp(),
    updated_at: serverTimestamp(),
  });

  const newEvent = await getDoc(eventRef);

  return { id: newEvent.id, ...newEvent.data() };
};

export const updateEvent = async ({ projectId, eventId, fields }) => {
  const eventRef = doc(firestore, "projects", projectId, "events", eventId);

  await updateDoc(eventRef, {
    ...fields,
    updated_at: serverTimestamp(),
  });

  return await retrieveEvent({ eventId, projectId });
};

export const addGuestToEvent = async ({ projectId, eventId, guestId }) => {
  await updateEvent({
    projectId,
    eventId,
    fields: {
      guests: arrayUnion(guestId),
    },
  });
};

export const removeGuestsToEvent = async ({ projectId, eventId, guests }) => {
  await updateEvent({
    projectId,
    eventId,
    fields: {
      guests: arrayRemove(...guests),
    },
  });
};

export const listEvents = async ({ projectId }) => {
  const eventsRef = collection(firestore, "projects", projectId, "events");

  const queryRef = query(eventsRef, orderBy("date", "asc"));

  const querySnapshot = await getDocs(queryRef);

  return Promise.all(
    querySnapshot.docs.map(async (event) => ({
      id: event.id,
      ...event.data(),
      questions: await listQuestions({ eventId: event.id, projectId }),
    })),
  );
};

export const retrieveEvent = async ({ eventId, projectId }) => {
  const eventRef = doc(firestore, "projects", projectId, "events", eventId);

  const eventSnapshot = await getDoc(eventRef);

  if (eventSnapshot.exists()) {
    return {
      id: eventSnapshot.id,
      ...eventSnapshot.data(),
    };
  } else {
    throw new Error("event-retrieve/no-such-document");
  }
};

export const deleteEvent = async ({ projectId, eventId }) => {
  // Delete the questions
  const eventQuestions = await listQuestions({ projectId, eventId });

  for (const question of eventQuestions) {
    await deleteQuestion({ projectId, eventId, questionId: question.id });
  }

  // Delete the answers
  const eventAnswers = await listAnswers({ projectId, eventId });

  for (const answer of eventAnswers) {
    await deleteAnswer({ projectId, eventId, answerId: answer.id });
  }

  // Delete the event
  const eventRef = doc(firestore, "projects", projectId, "events", eventId);
  await deleteDoc(eventRef);

  return "success";
};

const api = {
  create: createEvent,
  update: updateEvent,
  addGuest: addGuestToEvent,
  removeGuests: removeGuestsToEvent,
  list: listEvents,
  retrieve: retrieveEvent,
  delete: deleteEvent,
};

export default api;
