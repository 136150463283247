import omit from "lodash/omit";
import { storeStatus } from "../utils";
import { createEvent, deleteEvent, fetchEvents, resetEvents } from "./actions";

const initialState = {
  status: storeStatus.IDLE,
  allIds: [],
  byId: {},
  error: null,
};

const eventsReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case resetEvents.TYPE:
      return initialState;
    case fetchEvents.TYPE:
      return {
        ...state,
        status: storeStatus.LOADING,
      };
    case fetchEvents.success.TYPE:
      return {
        ...state,
        status: storeStatus.SUCCESS,
        allIds: payload.events.map((event) => event.id),
        byId: payload.events.reduce(
          (acc, event) => ({ ...acc, [event.id]: event }),
          {},
        ),
      };
    case fetchEvents.failure.TYPE:
      return {
        ...state,
        status: storeStatus.ERROR,
        error: payload.errorMessage,
      };
    case deleteEvent.success.TYPE:
      const allIdsWithoutDelete = state.allIds.filter(
        (id) => id !== payload.eventId,
      );

      return {
        ...state,
        allIds: allIdsWithoutDelete,
        byId: omit(state.byId, payload.eventId),
      };
    case createEvent.success.TYPE:
      return {
        ...state,
        allIds: [payload.event.id, ...state.allIds],
        byId: {
          ...state.byId,
          [payload.event.id]: payload.event,
        },
      };

    default:
      return state;
  }
};

export default eventsReducer;
